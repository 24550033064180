<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Tambah Kelas Siswa
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="student_id" rules="required">
                <b-form-group label="Siswa" label-for="student_id" :state="errors.length > 0 ? false : null">
                  <v-select id="student_id" v-model="dataParent.student_id" :reduce="(student_id) => student_id.id"
                    placeholder="Pilih Siswa" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa"
                    label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="class_id" rules="required">
                <b-form-group label="Kelas" label-for="class_id" :state="errors.length > 0 ? false : null">
                  <v-select id="class_id" v-model="dataParent.class_id" :reduce="(class_id) => class_id.id"
                    placeholder="Pilih Kelas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass"
                    label="class_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                <b-form-group label="Tahun" label-for="academic_year_id" :state="errors.length > 0 ? false : null">
                  <v-select id="academic_year_id" v-model="dataParent.academic_year_id"
                    :reduce="(academic_year_id) => academic_year_id.id" placeholder="Pilih Tahun"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listacademicyear" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="level" rules="required">
                <b-form-group label="Level" label-for="level" :state="errors.length > 0 ? false : null">
                  <v-select id="level" v-model="dataParent.level" :reduce="(level) => level.id" placeholder="Pilih Level"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listLevel" label="level_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="previous_class_id" rules="required">
                <b-form-group label="Kelas Sebelumnya" label-for="previous_class_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="previous_class_id" v-model="dataParent.previous_class_id"
                    :reduce="(previous_class_id) => previous_class_id.id" placeholder="Pilih Kelas Sebelumnya"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listClass" label="class_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="previous_academic_year_id" rules="required">
                <b-form-group label="Tahun Akademik Sebelumnya" label-for="previous_academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="academic_year_id" v-model="dataParent.previous_academic_year_id"
                    :reduce="(previous_academic_year_id) => previous_academic_year_id.id"
                    placeholder="Pilih Tahun Akademik Sebelumnya" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listacademicyear" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="previous_level" rules="required">
                <b-form-group label="Level Sebelumnya" label-for="previous_level"
                  :state="errors.length > 0 ? false : null">
                  <v-select id="level" v-model="dataParent.previous_level" :reduce="(previous_level) => previous_level.id"
                    placeholder="Pilih Level Sebelumnya" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listLevel" label="level_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Tipe" label-for="type">
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <b-form-input id="type" v-model="dataParent.type" type="text" :state="errors.length > 0 ? false : null"
                    placeholder="Tipe" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Keterangan" label-for="description">
                <validation-provider #default="{ errors }" name="description" rules="required">
                  <b-form-input id="description" v-model="dataParent.description" type="text"
                    :state="errors.length > 0 ? false : null" placeholder="Keterangan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        student_id: '',
        class_id: '',
        previous: '',
        academic_year_id: '',
        level: '',
        previous_class_id: '',
        previous_academic_year_id: '',
        previous_academic_year_id: '',
        previous_level: '',
        type: '',
        description: '',
      },
      listSiswa: [],
      listClass: [],
      listacademicyear: [],
      listLevel: [],
    }
  },
  computed: {
  },
  created() {
    this.getAcademicYears()
    this.getClass()
    this.getsiswa()
    this.getLevels()
  },
  methods: {
    async getLevels() {
      try {
        const response = await this.$http.get('/levels')
        this.listLevel = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Class Not Success')
        console.log(err)
      }
    },
    async getAcademicYears() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listacademicyear = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Academic Not Success')
        console.log(err)
      }
    },
    async getClass() {
      try {
        const response = await this.$http.get('/masterclasses')
        this.listClass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/academicclassstudents', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Kelas Siswa Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-kenaikan-kelas' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Kelas siswa Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
